import React from "react";
import { Button } from "primereact/button";
import styles from "./index.module.scss";

const Home = () => {
  return (
    <div>
      <h1 className={styles.title}>Meshub Conference User Guide</h1>
      <a className={styles.link} href="/videoConference.pdf" target="_blank">
        <Button label="Video conference" />
      </a>
      <a
        className={styles.link}
        href="/privateVideoConference.pdf"
        target="_blank"
      >
        <Button label="Private video conference" />
      </a>
    </div>
  );
};

export default Home;
